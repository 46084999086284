import React, { ReactElement, useEffect } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { fetchLogin, checkIsAlreadyLoggedIn } from "../store/slices/user";
import { Link, useHistory } from "react-router-dom";
import { navigate } from "gatsby";
import styled from "styled-components";
import { withAuthenticator } from "@aws-amplify/ui-react";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  height: 200px;
  margin-bottom: 24px;
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 75%;
  @media only screen and (max-width: 720px) {
    width: calc(100% - 64px);
  }
`;

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLoggedIn,
    loading,
    hasErrors,
    errorMsg,
    hasCheckedIfAlreadyLoggedIn,
  } = useSelector((state) => state).userSlice;

  const [formState, setFormState] = React.useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [history, isLoggedIn]);

  useEffect(() => {
    if (!hasCheckedIfAlreadyLoggedIn) {
      dispatch(checkIsAlreadyLoggedIn());
    }
  }, [dispatch, hasCheckedIfAlreadyLoggedIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = formState;
    dispatch(fetchLogin({ username, password }));
    // const cognitoId = localStorage.getItem('sub')
    // dispatch(fetchLogin(cognitoId))
  };
  const handleChange = (e) => {
    if (e.target.name === "username") {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  };

  const useStyles = makeStyles((theme) => ({
    input: {
      color: "#FFF",
    },
  }));
  const classes = useStyles();

  return (
    <div
      style={{
        height: "100vh",
        marginTop: -12,
        paddingTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <h1 style={{ color: "white", marginBottom: 4 }}>Bryan Gottschalk</h1>
      <p style={{ marginBottom: 24 }}>Software Engineer</p>
      {isLoggedIn ? (
        <div style={{ color: "white" }} role="alert">
          Success! You will be redirected shortly.
        </div>
      ) : (
        <React.Fragment>
          <Form onSubmit={handleSubmit}>
            <Container>
              <TextField
                inputProps={{
                  style: {
                    textTransform: "lowercase",
                    background: "#EDEDED",
                    color: "black",
                  },
                }}
                fullWidth
                onChange={handleChange}
                label="Username"
                id="username"
                name="username"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                  style: { color: "#EDEDED" },
                }}
              />
            </Container>
            <Container>
              <TextField
                inputProps={{
                  style: { background: "#EDEDED", color: "black" },
                }}
                fullWidth
                variant="standard"
                onChange={handleChange}
                label="Password"
                name="password"
                type="password"
                id="password"
                InputLabelProps={{
                  shrink: true,
                  style: { color: "#EDEDED" },
                }}
              />
            </Container>
            <Container
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                aria-label="Log in"
                style={{
                  backgroundColor: "#21890e",
                  color: "white",
                }}
                variant="contained"
                type="submit"
              >
                Log in
              </Button>
              <Button
                aria-label="Contact"
                style={{
                  backgroundColor: "#2b79c6",
                  color: "white",
                }}
                variant="contained"
              >
                <a
                  style={{ borderBottom: "none" }}
                  href="mailto:bryanlgottschalk@gmail.com"
                >
                  Contact
                </a>
              </Button>
            </Container>
          </Form>
          {hasErrors && errorMsg && (
            <div style={{ color: "red", padding: 36 }}>{errorMsg}</div>
          )}
          {loading && (
            <>
              <span
                role="alert"
                style={{ position: "absolute", marginLeft: -9999 }}
              >
                Logging in
              </span>
              <CircularProgress style={{ margin: 16, color: "white" }} />
            </>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Login;
